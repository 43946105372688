// Fonts
$font-family-serif: Arial, Helvetica, serif;
$font-halyard-display-regular: 'Halyard Display Regular';
$font-halyard-display-light: 'Halyard Display Light';
$font-halyard-display-medium: 'Halyard Display Medium';
$font-halyard-text-medium: 'Halyard Text Medium';
$font-halyard-text-book: 'Halyard Text Book';
$font-joane-light: 'Joane T Light';
$font-joane-regular: 'Joane T Regular';

// Colors
$color-white: #fff;
$color-black: #000;
$color-gray-light: #d9d8d6;
$color-caption: rgba(var(--theme-copy-rgb), 0.55);

// Breakpoints
$breakpoint-md: 768px;
$breakpoint-lg: 1280px;

// Grid
$margin-sm: 2rem;
$margin-md: 4.8rem;
$margin-lg: auto;
$max-grid-width: 153rem;

// Transitions
$ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);
$ease-in-out-quart: cubic-bezier(0.76, 0, 0.24, 1);
$ease-in-out-quint: cubic-bezier(0.83, 0, 0.17, 1);
$ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);

// z-index
$z-100: 100;
$z-200: 200;
$z-300: 300;
$z-400: 400;
$z-900: 900;

// Filters
$filter-bar-padding-sm: 10.5rem;
$filter-bar-padding-md: 12rem;
$filter-bar-padding: 3.2rem;

// Sections
$section-spacing-sm: 8.8rem;
$section-spacing-md: 12rem;
$section-spacing-lg: 16rem;
