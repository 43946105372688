@import '/styles/base.scss';

.newsletterModule {
  @include md-only {
    align-items: center;
  }
}

.newsletter {
  margin-left: -$margin-sm;

  @include md {
    margin-left: 0;
  }
}
