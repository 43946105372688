@import '/styles/base.scss';

.eventCard {
  @include sm-only {
    margin-bottom: 8.8rem;
    margin-left: -$margin-sm;
  }
}

.noEventsWrapper {
  align-self: flex-start;
  opacity: 0.6;

  @include sm-only {
    margin-bottom: 8.8rem;
  }
}
