@import '/styles/base.scss';

.emphasized {
  margin-right: -$margin-sm;
  margin-left: -$margin-sm;

  @include md {
    margin-right: 0;
    margin-left: 0;
  }
}

.featured {
  margin-bottom: 2.4rem;

  @include lg {
    margin-bottom: 4rem;
  }
}
